/**
 * Default Variables.
 *
 * Modify this file to provide default Bootstrap Framework variables. The
 * Bootstrap Framework will not override any variables defined here because it
 * uses the `!default` flag which will only set their default if not already
 * defined here.
 *
 * You can copy existing variables directly from the following file:
 * ./THEMENAME/bootstrap/assets/stylesheets/bootstrap/_variables.scss
 */

// Set the proper directory for the Bootstrap Glyphicon font.
$icon-font-path: '../webfonts/';

$orange2:   #e06b10;
$red:       #b20023;
$blue:      #002475;
$cyan:      #3366cc;
$yellow:    #ffc100;
$green:     #43cc33;
$magenta:   #cc334c;
