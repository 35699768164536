
// Default variables.
@import "fonts";


@import "custom-overrides";

@import "default-variables";

// Bootstrap Framework.
@import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

@import "base-init";

@import "text-styles";

@import "custom-mixins";

body {
  background: $gray-lighter;
  .highlighted {
    background: $white;
  }
}

#navbar {
  border: none;
  background: $orange;
  color: $white;
  .navbar-header {
    .name {
      display: none;
    }
    .logo {
      padding-top: 6px;
      img {
        max-height: 47px;
      }
    }
    .navbar-toggle {
      padding: 0;
      width: 52px;
      height: 36px;
      position: relative;
      margin: 20px auto;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      span.bar {
        margin: 0;
        padding: 0;
        display: block;
        position: absolute;
        height: 6px;
        width: 100%;
        background: $white;
        border-radius: 6px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: 15px;
        }
        &:nth-child(4) {
          top: 30px;
        }
      }
      &.open {
        span.bar {
          &:nth-child(1) {
            top: 15px;
            width: 0%;
            left: 50%;
          }
          &:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
          &:nth-child(4) {
            top: 15px;
            width: 0%;
            left: 50%;
          }
        }
      }
    }
  }
  a {
    font-size: 16px;
  }
}
.main-container {
  padding-top: 25px;
  padding-bottom: 25px;
}
.sidebar {
  .region {
    padding: 15px;
    background: $white;
    .block:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}
.page-node-11 {
  .field-name-body {
    iframe {
      width:auto;
      min-width: 500px;
    }
  }
}
.node-type-visual-page {
  .main-container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// Elementi standard //
.icon-file-type {
  color: $brand-primary;
}
.tabs {
  .nav-tabs {
    a {
      font-family: $font-family-serif;
      color: $gray-base;
    }
  }
  .tab-content {
    border: 1px solid $nav-tabs-border-color;
    border-top: none;
    padding: 15px;
  }
}
.btn.btn-mod {
  @include btn_default();
  &.btn-medium {
    font-size: 1.2em;
    line-height: 1.2em;
  }
}
.social-home-links {
  text-align: center;
  font-size: 24px;
  p {
    margin: 0;
  }
  .link-text {
    color: $white;
    display: block;
  }
  a.social-icon {
    color: $white;
    padding: 5px;
  }
}
.counter-item {
  padding-top: 45px;
  padding-bottom:45px;
  .el-icon {
    font-size: 32px;
  }
  .text {
    font-size: .9em;
  }
}
// Nodi //
article {
  &.node-bando {
    background: $white;
    padding: 15px;
    margin-bottom: 45px;
    .node-page-title {
      font-size: 32px;
      font-weight: 600;
      padding: 25px 0;
    }
    .submitted {
      color: $gray;
      font-size: 14px;
      i {
        font-size: 18px;
        color: $gray-dark;
        padding-right: 6px;
      }
    }
    &:not(.node-teaser) {
      .field-name-field-subtitle {
        padding: 25px 0;
        font-family: $font-family-serif;
        font-size: 1.4em;
        font-weight: 600;
        line-height: 1.5;
      }
      .field-name-field-tipologia {
        color:  $gray;
        font-size: 14px;
        font-style: italic;
      }
      .field-name-field-attachments {
        padding: 15px 0;
      }
    }
  }
  &.node-evento {
    background: $white;
    padding: 15px;
    margin-bottom: 45px;
    .field-name-field-date {
      &:before {
        content: "\f073";
        font-family: "Font Awesome 5 Free";
        float: left;
        padding-right: 5px;
        color: $gray-dark;
        font-size: 1.3em;
        line-height: 1.2em;
      }
    }
    .field-name-field-space {
      &:before {
        content: "\f3c5";
        font-family: "Font Awesome 5 Free";
        float: left;
        padding-right: 5px;
        color: $gray-dark;
        font-size: 1.3em;
        line-height: 1.2em;
      }
    }
  }
  &.node-staff {
    background: $white;
    padding: 15px;
    margin-bottom: 30px;
    .node-page-title {
      font-size: 32px;
      font-weight: 600;
      padding: 25px 0;
    }
    .field-name-title-field {
      font-weight: 600;
      font-size: 1.2em;
      padding-top: 5px;
    }
    .field-name-field-subtitle {
      color: $gray;
      line-height: 1em;
      padding-bottom: 5px;
      font-size: 0.9em;
    }
    .field-name-body {
      font-size: 0.9em;
      line-height: 1.4em;
      padding-top: 5px;
    }
    .field-name-field-links {
      @include clearfix;
      .field-item {
        display: inline-block;
        padding-right: 10px;
        &:last-child {
          padding-right: 0;
        }
        font-size: 32px;
        a {
          color: $gray-light;
          @include transition(color);
          &:hover {
            color: $brand-primary;
          }
        }
      }
    }
  }
}
// Taxonomy terms //
.taxonomy-term {
  &.vocabulary-spaces {
    background: $white;
    padding: 15px 0;
    margin-bottom: 25px;
  }
}
.bg-page-top {
  padding: 15px;
  background-color: $white;
  min-height: 180px;
}
.section-title {
  background: $gray-dark;
  color: $white;
  padding-top: 15px;
  padding-bottom: 15px;
  .page-header {
    border: none;
    padding: 0;
    margin-top: 14px;
    margin-bottom: 14px;
  }
}
// Blocchi //
#block-fullcalendar-legend-fullcalendar-legend {
  padding: 15px 10px;
  .block-content {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .fc-event-default {
    margin: 5px;
    font-size: 16px;
    min-width: 88px;
    text-align: center;
    &.selected,
    &:hover {
      color: $white;
      opacity: 0.8;
    }
    &.colors-taxonomy-term-all {
      background: $gray-light;
      color: $gray-base;
    }
  }
}
#block-locale-language {
  margin-top: 10px;
  padding-top: 10px;
  border-top:1px solid lighten($brand-primary, 50);
  padding-bottom: 10px;
  @include transition();
  ul:not(.contextual-links) {
    list-style: none;
    padding: 0;
    margin:0;
    display: table;
    @include clearfix;
    li {
      display: inline-block;
      a {
        text-transform: uppercase;
        font-size: 18px;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        display: block;
        color: $white;
        &.active {
          font-weight: 600;
          background: rgba($white, 0.1);
        }
        &:hover {
          background: rgba($white, 0.2);
          text-decoration: none;
        }
      }
    }
  }
}
#block-webform-client-block-24 {
  form {
    padding: 25px 0;
    .webform-component-checkboxes {
      .element-invisible {
        margin:0;
      }
      .help-block {
        margin-top:-10px;
        margin-left: 20px;
      }
    }
  }
}
.block-views {
  .views-exposed-form {
    position: relative;
    .views-exposed-widget:not(.views-submit-button) {
      width: 100%;
      padding-right: 42px;
      label {
        display: none;
      }
    }
    .views-submit-button {
      position: absolute;
      padding-right: 0;
      right: 0;
      top:0;
      z-index: 100;
      button {
        margin:0;
        &.form-submit {
          background: $gray-base;
          color: $white;
          border-color: $gray-base;
        }
      }
    }
  }
}
// VIEWS //

.view-id-spaces {
  .views-column {
    matgin-bottom: 25px;
    position: relative;
    .views-field-name-field-et {
      padding: 10px 15px;
      color: $white;
      &.colors-taxonomy-term-7{
        background: $red;
      }
      &.colors-taxonomy-term-4 {
        background:  $blue;
      }
      &.colors-taxonomy-term-5 {
        background: $yellow;
      }
      &.colors-taxonomy-term-6 {
        background: $orange2;
      }
      .subtitle-spazio {
        font-size: 14px;
        line-height: 1.5em;
      }
    }
    .views-field-description-field-et {
      font-size: 14px;
      line-height: 1.5em;
      padding: 10px 15px 10px 0;
    }
    .link-spazio {
      position: absolute;
      right: 15px;
      bottom: 20px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 18px solid $brand-primary;
    }
  }
}
.view-bandi {
  &.view-display-id-block_1,
  &.view-display-id-block_2 {
    .views-row {
      display: block;
      @include clearfix;
      margin-bottom: 10px;
      &:not(:last-child) {
        padding-bottom: 15px;
        border-bottom: 1px solid $nav-tabs-border-color;
      }
      .views-field-field-image {
        float: left;
        margin-right: 15px;
      }
      .views-field-title-field-et {
        font-family: $font-family-serif;
        line-height: 1.4em;
        font-weight: 600;
      }
      .views-field-created {
        font-size: 13px;
        color: $gray;
      }
    }
  }
  &.view-display-id-page {
    .views-row {
      background: $white;
      padding: 15px;
      margin-bottom: 25px;
      .views-field-title-field-et {
        padding: 15px 0;
        font-size: 32px;
        font-weight: 600;
        a {
          color: $gray-base;
        }
      }
      .views-field-created {
        color: $gray;
        font-size: 14px;
        i {
          font-size: 18px;
          color: $gray-dark;
          padding-right: 6px;
        }
      }
      .views-field-field-subtitle-et {
        padding: 15px 0;
      }
      .views-field-view-node {
        padding-top: 25px;
        border-top: 1px solid $gray-lighter;
        padding-bottom: 15px;
        a {
          @include button-variant($white, $brand-primary, $brand-primary);
          @include button-size(8px, 16px, 14px,16px,0);
        }
      }
    }
  }
}
.view-eventi {
  .fullcalendar {
    .fc-header {
      vertical-align: middle;
      td {
        vertical-align: middle;
        &.fc-header-left {
          text-align: right;
        }
        &.fc-header-right {
          text-align: left;
        }
      }
      .fc-button {
        border: none;
        background: transparent;
        padding:0;
        width: 60px;
        &:after {
          content: "";
          position: relative;
          display: block;
          margin: auto;
          width: 0;
          height: 0;
          line-height: 0;
          border-top: 14px solid transparent;
          border-bottom: 14px solid transparent;
          border-left: 28px solid $gray-base;
        }
        .fc-text-arrow {
          display: none;
        }
        &.fc-button-prev {
          &:after {
            border-left: 0 solid transparent;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-right: 28px solid $gray-base;
          }
        }
      }
    }
    .fc-content {
      table {
        td,
        th {
          border-width: 5px;
          border-color: $gray-lighter;
        }
      }
      .fc-day-header {
        font-weight: 400;
        font-size: 1.1em;
      }
      .fc-day {
        background: $white;
        &.fc-today {
          background: $gray-lighter;
        }
        .fc-day-number {
          float: none;
          text-align: center;
          background: #5f88a7;
          color: $white;
        }
      }
    }
    .fc-event-container {
      .fc-event {
        border-radius: 0;
        background-color: $white;
        color: $gray-base;
        border-width: 6px 0 0 0;
        padding: 5px;
        line-height: 1.4em;
        .fc-event-time {
          display: block;
          background: transparent;
          border: 0;
          color: $gray-base;
          font-weight: 400;
          &:before {
            content: "\f017";
            font-family: "Font Awesome 5 Free";
            padding-right: 5px;
            color: $gray;

          }
        }
        .fc-event-title {
          display: none;
          span {
            &:last-child {
              &:before {
                content: "\f3c5";
                font-family: "Font Awesome 5 Free";
                padding-right: 5px;
                color: $gray-dark;
                font-weight: 900;

              }
            }
          }
        }
      }
    }
  }
  &.view-display-id-block_1 {
    .views-row {
      display: block;
      @include clearfix;
      margin-bottom: 10px;
      &:not(:last-child) {
        padding-bottom: 15px;
        border-bottom: 1px solid $nav-tabs-border-color;
      }
      &:first-child {
        border-top: 1px solid $nav-tabs-border-color;
        padding-top: 15px;
      }
      .views-field-field-image {
        float: left;
        margin-right: 15px;
      }
      .views-field-title-field-et {
        font-family: $font-family-serif;
        line-height: 1.4em;
        font-weight: 600;
      }
      .views-field-field-date {
        font-size: 14px;
      }
      .views-field-field-tipo-evento {
        color: $gray;
        font-size: 13px;
        line-height: 1.4em;
      }
    }
    .more-link {
      border-top: 1px solid $nav-tabs-border-color;
      padding-top: 20px;
      padding-bottom: 5px;
      margin-top: 15px;
      text-align: right;
      a {
        @include button-variant($white, $brand-primary, $brand-primary);
        @include button-size(8px, 16px, 14px,16px,0);
      }
    }
  }
  &.view-display-id-block_2 {
    .view-empty {
      background: $white;
      padding: 15px;
    }
    .view-content {
      .views-column {
        min-height: 220px;
        background: $white;
        background-clip: content-box;
        padding-top: 15px;
        padding-bottom: 15px;
        .views-field {
          padding: 0 15px;
          &:first-child {
            padding-top: 15px;
          }
          &:last-child {
            padding-bottom: 15px;
          }
        }
      }
    }
    .more-link {
      border-top: 1px solid $nav-tabs-border-color;
      padding-top: 20px;
      padding-bottom: 5px;
      margin-top: 15px;
      text-align: right;
      a {
        @include button-variant($white, $brand-primary, $brand-primary);
        @include button-size(8px, 16px, 14px,16px,0);
      }
    }
  }
}

// Webform
.webform-client-form {
  .form-actions {
    .webform-submit {
      @include button-variant($white, $brand-primary, $brand-primary);
      @include button-size(15px, 32px, 18px,18px,0);
      text-transform: uppercase;
    }
  }
}
// counter

.counter-group {
  margin: 45px auto 25px;
  display: inline-block;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
  }

}
.counter-block {
  margin-right: 10px;
  float:left;
  &:last-child {
    margin-right: 0;
  }
  .counter {
    position: relative;
    width: 64px;
    height: 46px;
    overflow: hidden;
    &.with-hundreds {
      width: 100px;
      .number {
        width: 30.66%;
        &.tens {
          left: 33.33%;
        }
        &.units {
          left: 66.66%;
        }
        &.hundreds {
          display: block;
          left: 0;
        }

      }
    }
    .number {
      padding: 1%;
      width: 46%;
      height: 98%;
      background-color: $gray-base;
      color: $white;
      position: absolute;
      font-size: 24px;
      line-height: 42px;
      top: -10000px;
      text-align: center;
      &.tens {
        left: 0;
      }
      &.units {
        left: 50%;
      }
      &.hundreds {
        display: none;
      }
      &.show {
        top: 0;
        z-index: 2;
      }
      &.hidden-down {
        top: 100%;
      }
      &.hidden-up {
        top: -100%;
        z-index: 100;
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
  .counter-caption {
    font-size: 60%;
    line-height: 2em;
    text-align: center;
  }
}
@media all and (max-width: 767px) {
  //.navbar-collapse.in {
  //  height: 400px;
  //}
}
@media all and (min-width: 768px) {
  #navbar {
    .navbar-header {
      display: none;
    }
    #navbar-collapse {
      padding:0;
      nav {
        ul.menu {
          li {
            display: table-cell;
            text-align: center;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              display: inline-block;
              width: 1px;
              height: 20px;
              top: 10px;
              right: 0;
              z-index: 10;
              background: $white;
            }
            &:first-child {
              &:before {
                content: '';
                position: absolute;
                display: inline-block;
                width: 1px;
                height: 20px;
                top: 10px;
                left: 0;
                z-index: 10;
                background: $white;
              }
            }
            a {
              display: block;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  #block-locale-language {
    margin-top: 0;
    padding-top: 0;
    border-top:none;
    padding-bottom: 0;
    float: right;
    @include transition();
    ul:not(.contextual-links) {
      li {
        a {
          font-size: 16px;
          width: 42px;
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
  .main-container {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .bg-page-top {
    min-height: 240px;
  }
  #block-fullcalendar-legend-fullcalendar-legend {
    padding: 25px 10px;
  }
  .social-home-links {
    text-align: right;
    font-size: 32px;
    .link-text {
      display: inline;
    }
    a.social-icon {
      color: $white;
      padding: 5px;
    }
  }
  .counter-item {
    .el-icon {
      font-size: 36px;
    }
  }
  .counter-block {
    margin-right: 20px;
    .counter {
      width: 136px;
      height: 94px;
      &.with-hundreds {
        width: 300px;
      }
      .number {
        font-size: 72px;
        line-height: 90px;
      }
    }
    .counter-caption {
      font-size: 90%;
    }
  }
  .view-id-spaces {
    .views-column:nth-child(3) {
      clear: left;
    }
  }
}
@media all and (min-width: 992px) {
  #navbar {
    #navbar-collapse {
      nav {
        ul.menu {
          li {
            min-width: 128px;
            a {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  #block-locale-language {
    margin-top: 0;
    padding-top: 0;
    border-top:none;
    padding-bottom: 0;
    float: right;
    @include transition();
    ul:not(.contextual-links) {
      li {
        a {
          font-size: 18px;
          width: 48px;
          height: 44px;
          line-height: 44px;
        }
      }
    }
  }
  .main-container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .bg-page-top {
    min-height: 320px;
  }

  .view-eventi {
    .fullcalendar {
      .fc-event-container {
        .fc-event {
          .fc-event-title {
            display: block;
          }
        }
      }
    }
  }
  .counter-item {
    .el-icon {
      font-size: 42px;
    }
  }
  article {
    &.node-staff {
      padding: 20px;
      .field-name-field-subtitle,
      .field-name-title-field {
        padding-right: 90px;
      }
      .field-name-body {
        clear: both;
        padding-top: 15px;
      }
      .details {
        position: relative;
        min-height: 230px;
        .field-name-field-links {
          position: absolute;
          top:5px;
          right: 0;
          .field-item {
            display: inline-block;
            padding-right: 10px;
            &:last-child {
              padding-right: 0;
            }
            font-size: 32px;
            a {
              color: $gray-light;
              @include transition(color);
              &:hover {
                color: $brand-primary;
              }
            }
          }
        }
      }
    }
  }
  // Taxonomy terms //
  .taxonomy-term {
    &.vocabulary-spaces {
      padding: 25px 10px;
      margin-bottom: 45px;
    }
  }
  .view-staff {
    .staff-items-4 {
      .views-row:nth-child(2n+1) {
        clear: both;
      }
    }
    .staff-items-5 {
      .views-row:nth-child(3) {
        clear: both;
      }
    }
  }
  .view-id-spaces {
    .views-column:nth-child(3) {
      clear: none;
    }
  }
}
@media all and (min-width: 1200px) {
  #navbar {
    #navbar-collapse {
      margin-left: 0;
      margin-right: 0;
      nav {
        ul.menu {
          li {
            min-width: 128px;
            a {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  #block-locale-language {
    @include transition();
    ul:not(.contextual-links) {
      li {
        a {
          font-size: 18px;
          width: 48px;
          height: 44px;
          line-height: 44px;
        }
      }
    }
  }
  .bg-page-top {
    min-height: 380px;
  }
  .main-container {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  article {
    &.node-bando {
      padding: 20px;
    }

    &.node-evento {
      padding: 20px;
    }
  }
  #block-fullcalendar-legend-fullcalendar-legend {
    padding: 45px 15px 35px;
    .fc-event-default {
      font-size: 18px;
      min-width: 96px;
      text-align: center;
      padding: 8px 20px;
    }
  }
  .view-bandi {
    &.view-display-id-page {
      .views-row {
        padding: 20px;
        margin-bottom: 45px;
      }
    }
  }
  .social-home-links {
    a.social-icon {
      color: $white;
      font-size: 42px;
    }
  }
  .counter-item {
    .el-icon {
      font-size: 48px;
    }
  }
}