
@font-face {
  font-family: "D-DIN";
  src: url('../webfonts/d-din/D-DIN.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "D-DIN";
  src: url('../webfonts/d-din/D-DIN-Italic.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "D-DIN";
  src: url('../webfonts/d-din/D-DIN-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "D-DINCondensed";
  src: url('../webfonts/d-din/D-DINCondensed.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "D-DINCondensed";
  src: url('../webfonts/d-din/D-DINCondensed-Bold.otf') format('truetype');
  font-weight: 700;    /* or whatever weight you need to use for "bolder" */
  font-style: normal;
}
@font-face {
  font-family: "D-DINExp";
  src: url('../webfonts/d-din/D-DINExp.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "D-DINExp";
  src: url('../webfonts/d-din/D-DINExp-Italic.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "D-DINExp";
  src: url('../webfonts/d-din/D-DINExp-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}