
// CUSTOM ELICOS
p.skip-link__wrapper {
  margin: 0;
}

.gutter-width {
  padding-left: 0;
  padding-right: 0;

  .row {
    margin-left: 0;
    margin-right: 0;

    div:not(.gutter-width) {

      .row {
        margin-left: -$grid-gutter-width / 2;
        margin-right: -$grid-gutter-width / 2;
      }
    }
  }
  .container {
    .row {
      margin-left: -$grid-gutter-width / 2;
      margin-right: -$grid-gutter-width / 2;
    }
  }
}
ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 25px;
}

html,
body {
  :focus {
    outline: none;
  }
  ::-moz-selection {

  }
  ::selection {

  }
  a {
    // @include transition();
    &:focus {
      outline: none;
    }
  }
  iframe,
  object,
  embed {
    width: 100%;
  }
  .mbYTP_wrapper {
    iframe {
      max-width: none;
    }
  }
  /* Select */
  .shs-wrapper-processed {
    select {
      display: block;
      width: 100%;
      height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
      padding: $padding-base-vertical $padding-base-horizontal;
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: $input-color;
      background-color: $input-bg;
      background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
      border: 1px solid $input-border;
      border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
    }
  }
}
body {
  &.admin-menu {
    #header {
      top: 50px;
    }
    .slicknav_menu {
      position: relative;
      z-index: 2000;
    }
  }
  .bg-dark,
  .bg-dark .YTPOverlay {
    &:before {
      background-color: rgba(0,0,0, .6);
    }
  }
  .bg-scroll {
    .section-text {
      position: relative;
    }
  }
  .bg-light:before {
    background: rgba(255,255,255, .9);
  }
}

/* Background */
.page-section {
  background-size: cover;
  &.bg-primary {
    background-color: $brand-primary;
    color: $white;
  }
  &.bg-secondary {
    background-color: $gray-dark;
    color: $white;
  }
  &.bg-gray-lighter {
    background-color: $gray-lighter;
  }
  &.bg-gray {
    background-color: $gray-light;
  }
}

/* MESSAGES */

#region-messages {
  .alert-dismissable,
  .alert-dismissible {
    padding-left: 36px;
  }
}

/* SAFE BACKGROUND */

.safe-bg {
  height: 480px;
  min-height: 360px;
  background-attachment: inherit;
  display: table;
  width: 100%;
}

.safe-bg-content {
  height: 320px;
  min-height: 240px;
}


/* Lists */

ul.inline {
  margin: 0;
  padding: 0;
  display: table;
  list-style: none;
  li {
    margin: 0;
    padding: 0;
    display: inline;
  }
}

/* Box content */
.box-content {
  display: table;
  width: 100%;
  &.border-bottom-block {
    &:after {
      content: " ";
      width: 100%;
      border-bottom: 2px solid #ffcd00;
      display: table;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 10px 15px;
    }
  }
}

/* TABLE */
.table {
  table {
    width: 100%;
    border-collapse: collapse;
  }
  tr:nth-of-type(odd) {
    background: #FFF;
  }
  th {
    background: #FFF;
    color: $text-color;
    font-weight: 400;
    img {
      display: inline;
      margin-left: 3px;
    }
  }
  td, th {
    padding: 6px;
    border-top: 1px solid $gray-light;
    text-align: left;
  }
}

/* PAGINATION */
.pager {
  li {
    &.pager-current {
      display: inline-block;
      padding: 5px 14px;
      background-color: #FFF;
      border: 1px solid $gray-lighter;
      border-radius: 0;
    }
    a {
      display: inline-block;
      padding: 5px 14px;
      color: $brand-primary;
      background-color: $gray-lighter;
      border: 1px solid $gray-lighter;
      border-radius: 0;
      &:hover {
        background-color: darken($gray-lighter, 6%);
      }
    }
  }
}
/* COOKIE POLICY POPUP */
#sliding-popup {
  background-color: #000 !important;
  .popup-content {
    font-weight: 400;
    font-size: 13px;
    padding: 0 0 6px 0;
    #popup-text {
      margin: 5px 0 0;
      font-weight: 300;
      p, h1, h2, h3, h4 {
        font-weight: 400;
        font-size: 13px;
      }
      strong {
        font-weight: 400;
      }
      address {
        margin-bottom: 5px;
      }
    }
    #popup-buttons {
      button {
        background: transparent;
        text-shadow: none;
        box-shadow: none;
        border-radius: 0;
        font-weight: 400;
        font-size: 12px;
        padding: 2px 12px;
        line-height: 1.5em;
        border: 1px solid #FFF;
        color: #FFF;
        opacity: 0.8;
        @include transition(opacity);
        &:hover {
          background: transparent;
          opacity: 1;
        }
      }
    }
  }
}

/* Vertical align background */
.banner-section {
  > .v-align-content {
    display: table;
    height: 100%;
    width: 100%;
    >  .v-align-section {
      display: table-cell;
      vertical-align: middle;
      border-collapse: collapse;
      border-spacing: 0;
      background-color: transparent;
    }
  }
}

/* Form */

.captcha {
  margin-bottom: 18px;
}

@media all and (max-width: 767px) {
  .col-xs-0 {
    display: none;
  }
  .xs-bg-none {
    background-image: none !important;
    background-color: transparent !important;
  }
}

@media all and (max-width: 419px){
  .captcha {
    margin-left: -6px;
  }
}
@media all and (max-width: 479px){
  .safe-bg-content,
  .safe-bg {
    height: 480px;
  }
}

@media all and (max-width: 639px) {

  table.table.responsive-table-header {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr {
      border: 1px solid $gray-light;
      width: 100%;
    }

    td {
      /* Behave like a "row" */
      border: none;
      position: relative;
      padding-left: 35%;
      white-space: normal;
      text-align: left;
      min-height: 32px;
      display: inline-block;
      width: 100%;
      &:last-child {
        border-bottom: none;
      }
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 0;
      left: 0;
      width: 30%;
      padding: 9px 5px;
      height: 100%;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
    }

    /*
    Label the data
    */
    td:before {
      content: attr(table-attr-data);
    }
  }
}

@media all and (min-width: 480px) and (max-width: 767px){
  .col-xs-0 {
    display: none;
  }
}
@media all and (min-width: 768px) and (max-width: 991px){
  .col-sm-0 {
    display: none;
  }

}
@media all and (min-width: 992px) and (max-width: 1199px){
  .col-md-0 {
    display: none;
  }
}

@media all and (min-width: 768px){
  .safe-bg-content {
    height: 480px;
  }
  .safe-bg {
    //padding-top: 50px;
    height: 480px;
    //margin-top: 0px;
    .v-align-content {
      margin-top: 0px;
    }
  }
  .row.va-row {
    position:relative;
    .vertical-align {
      position: absolute;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      > .col-sm-middle{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
@media all and (min-width: 992px){

  .safe-bg-content {
    height: 620px;
    &.safe-bg-large {
      height: 768px;
    }
    &.safe-bg-small {
      height: 420px;
    }
  }
  .safe-bg {
    height: 560px;
    .v-align-content {
      padding-top: 0;
    }
    &.safe-bg-large {
      height: 620px;
    }
    &.safe-bg-small {
      height: 264px;
    }
  }
}
@media all and (min-width: 1200px){

  .col-lg-0 {
    display: none;
  }

  .safe-bg-content,
  .safe-bg {
    height: 640px;
    &.safe-bg-small {
      height: 264px;
    }
  }
}
@media all and (min-width: 1600px){
  .safe-bg-content,
  .safe-bg {
    height: 768px;
    &.safe-bg-small {
      height: 264px;
    }
  }
}