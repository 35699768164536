

@mixin transition($property: all, $duration : 200ms, $type : linear, $delay: 0ms){
  // transition: <property> || <duration> || <timing-function> || <delay> [, ...];

  -webkit-transition: $property $duration $type $delay;
  -moz-transition:  $property $duration $type $delay;
  -ms-transition:  $property $duration $type $delay; /* IE10 is actually unprefixed */
  -o-transition:  $property $duration $type $delay;
  transition:  $property $duration $type $delay;
}

@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}


/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
          to top          : bottom,
          to top right    : bottom left,
          to right top    : left bottom,
          to right        : left,
          to bottom right : top left,
          to right bottom : left top,
          to bottom       : top,
          to bottom left  : top right,
          to left bottom  : right top,
          to left         : right,
          to left top     : right bottom,
          to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}

@mixin btn_default($text_color : $white, $color1 : $brand-primary, $color2 : darken($brand-primary, 10%), $direction : to bottom){
  font-weight: 400;
  padding: 10px 32px;
  border: 0;
  color: $text_color;
  background-color: $color1;
  @include transition(background-color);
  //@include linear-gradient($direction, $color1, $color2);
  &:hover {
    color: $text_color;
    background-color: lighten($color1, 5%);
    //@include linear-gradient($direction, darken($color1, 6), darken($color2, 6));
  }
  &:focus {
    outline: none;
    color: $text_color;
    background-color: lighten($color1, 5%);
  }
  $colors: ('white' $white)('blue' $brand-info)('default' $brand-primary)('secondary' $gray-dark)('gray' $gray-base)('darkgray' $gray-dark)('lightgray' $gray-light);

  $white: #FFF;

  @each $color in $colors{
    &.btn-#{nth($color, 1)} {
      color: nth($color, 2);
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
      border-width: 0;
    }

    @each $color2 in $colors{
      &.btn-#{nth($color, 1)}-#{nth($color2, 1)} {
        color: nth($color, 2);
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
        border-color: #{nth($color2, 2)};
        border-width: 2px;
        background-color: transparent;
        &:hover {
          border-color: lighten(nth($color2, 2), 5%);
          background-color: rgba(lighten(nth($color2, 2), 10%),0.2);
        }
      }
    }
    &.btn-icon {
      color: $white;
      background-color: $brand-primary;
      width: 24px;
      height: 24px;
      line-height: 24px;
      padding: 0;
      font-size:14px;
      margin-left: 3px;
    }
    &.no-link {
      color: darken(nth($color, 2), 10%);
      width: 32px;
      height: 32px;
      line-height: 30px;
      padding: 0;
      font-size:14px;
      margin-left: 3px;
      cursor: default;
      font-weight: 400;
    }
    &.bg-white {
      background: $white;
    }
  }
  $borders :('border-none' 0)('border-round' 10px)('border-circle' 50%);

  @each $border in $borders{
    &.#{nth($border, 1)} {
      border-radius: nth($border, 2);
      border-style: solid;
    }
  }
}
