/*==========  Mobile First Method  ==========*/

.cke_editable {
  cursor: text;
  background: #fff;
}

.small12,
.emphasis16,
.emphasis20,
.emphasis32 {
  font-family: $font-family-sans-serif;
}
.serif12,
.serif16,
.serif20,
.serif32 {
  font-family: $font-family-serif;
}
p.no-margin {
  margin: 0;
}
.uppercase {
  text-transform: uppercase;
}
.small12 {
  font-size: .9em;
}
/*.emphasis16*/
.emphasis16 {
  font-size: 1.1em;
  line-height: 1.3em;
}
/*.emphasis20*/
.emphasis20 {
  font-size: 1.3em;
  line-height: 1.3em;
}
/*.emphasis32*/
.emphasis32 {
  font-size: 2.4em;
  line-height: 1.3em;
}

/*.emphasis16*/
.serif12 {
  font-size: .9em;
  line-height: 1.3em;
}
/*.emphasis16*/
.serif16 {
  font-size: 1.2em;
  line-height: 1.3em;
}
/*.emphasis20*/
.serif20 {
  font-size: 1.4em;
  line-height: 1.3em;
}
/*.emphasis32*/
.serif32 {
  font-size: 1.6em;
  line-height: 1.3em;
}
.extra-light-text {
  font-weight: 100;
}
.light-text {
  font-weight: 300;
}
.regular-text {
  font-weight: 400;
}
.semibold-text {
  font-weight: 600;
}
.bold-text {
  font-weight: 700;
}
.extrabold-text {
  font-weight: 900;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2em;
}


h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: $line-height-computed / 2;
  margin-bottom: ($line-height-computed / 2);

  small,
  .small {
    font-size: 65%;
  }
}


h1 {
  font-size: 36px;
  &.hs-line-1 { /* H1 Big letter spaces */
    font-size: 28px;
    letter-spacing: 18px;
  }
  &.hs-line-12 { /* h1 80 */
    font-size: 32px;
  }
}
h2 {
  font-size: 30px;
  line-height: 1.3em;
  &.hs-line-7 { /* h2 64px */
    font-size: 28px;
  }
  &.hs-line-11 { /* h2 styled */
    font-size: 26px;
    letter-spacing: 0.1em;
  }
  &.hs-line-14 { /* h2 42px */
    font-size: 36px;
  }
}
h3 {
  font-size: 24px;
  line-height: 1.5em;
}
h4 {
  font-size: 20px;
  &.hs-line-8 { /* H4 Small letter spaces */
    font-size: 12px;
  }
}
h5 {
  font-size: 18px;
  line-height: 1.5em;
}
h6 {
  font-size: 14px;
  &.hs-line-6 { /* H6 transparent */
    font-size: 15px;
  }
}
.line-height-base {
  line-height: 1.4em;
}




body {
  .serif {
    .line-height-small {
      line-height: 1em;
      display: inherit;
    }

  }
  .emphasis {
    .line-height-small {
      line-height: 1.2em;
      display: inherit;
    }
  }
  .line-height-small {
    line-height: 1.2em;
    display: inherit;
  }
}
.st-white {
 text-shadow: 0px 0px 7px #FFFFFF;
}
.st-black {
  text-shadow: 0px 0px 7px #000000;
}

.quote36 {
  font-family: $font-family-serif;
  font-size: 20px;
  line-height: 1.4em;
  font-weight: 400;
  display: block;
  color: $gray;
  margin-top: 4px;
  margin-bottom: 12px;
  padding-top: 0;
  padding-bottom: 0;
  font-style: italic;
}
/* Custom, iPhone Retina */
@media all and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media all and (min-width : 480px) {



}

/* Small Devices, Tablets */
@media all and (min-width : 768px) {
  .small12 {
    font-size: .8em;
  }
  /*.emphasis16*/
  .emphasis16 {
    font-size: 1.2em;
    line-height: 1.4em;
  }
  /*.emphasis20*/
  .emphasis20 {
    font-size: 1.4em;
    line-height: 1.4em;
  }
  /*.emphasis32*/
  .emphasis32 {
    font-size: 1.8em;
    line-height: 1.4em;
  }

  /*.emphasis16*/
  .serif12 {
    font-size: .8em;
    line-height: 1.5em;
  }
  /*.emphasis16*/
  .serif16 {
    font-size: 1.2em;
    line-height: 1.4em;
  }
  /*.emphasis20*/
  .serif20 {
    font-size: 1.5em;
    line-height: 1.4em;
  }
  /*.emphasis32*/
  .serif32 {
    font-size: 1.8em;
    line-height: 1.4em;
  }
}

/* Medium Devices, Desktops */
@media all and (min-width : 992px) {


  /*.emphasis16*/
  //.emphasis16 {
  //  font-size: 20px;
  //}
  ///*.emphasis20*/
  //.emphasis20 {
  //  font-size: 30px;
  //}
  ///*.emphasis32*/
  //.emphasis32 {
  //  font-size: 42px;
  //}
  //
  //
  ///*.emphasis16*/
  //.serif16 {
  //  font-size: 20px;
  //}
  ///*.emphasis20*/
  //.serif20 {
  //  font-size: 20px;
  //}
  ///*.emphasis32*/
  //.serif32 {
  //  font-size: 32px;
  //}


  /* .quote36 */
  .quote36 {
    font-size: 22px;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-top: 0;
    padding-bottom: 0;
  }
  h1 {
    font-size: 48px;
    &.hs-line-1 { /* H1 Big letter spaces */
      font-size: 42px;
      letter-spacing: 18px;
    }
    &.hs-line-12 { /* h1 80 */
      font-size: 36px;
    }
  }
  h2 {
    font-size: 36px;
    line-height: 1.3em;
    &.hs-line-7 { /* h2 64px */
      font-size: 32px;
    }
    &.hs-line-11 { /* h2 styled */
      font-size: 32px;
      letter-spacing: 0.1em;
    }
    &.hs-line-14 { /* h2 42px */
      font-size: 38px;
    }
  }
  h3 {
    font-size: 24px;
    line-height: 1.5em;
  }
  h4 {
    font-size: 20px;
    &.hs-line-8 { /* H4 Small letter spaces */
      font-size: 12px;
    }
  }
  h5 {
    font-size: 18px;
    line-height: 1.5em;
  }
  h6 {
    font-size: 14px;
    &.hs-line-6 { /* H6 transparent */
      font-size: 15px;
    }
  }
  .line-height-base {
    line-height: 1.4em;
  }


}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}

